<template>
    <div class="layout-body">
        <div class="layout-title">
            <span v-if="number === 0">1矿用隔爆型高压真空配电装置PBG-630</span>
            <span v-if="number === 1">2矿用隔爆型高压真空配电装置PBG-640</span>
            <span v-if="number === 2">3矿用隔爆型高压真空配电装置PBG-650</span>
        </div>
        <div class="layout-list">
            <el-carousel :interval="4000" type="card" height="400" indicator-position="outside" :autoplay="false" @change="onChange">
              <el-carousel-item v-for="item in 3" :key="item"  @click.native="onLink">
                  <div class="item"></div>
              </el-carousel-item>
            </el-carousel>
        </div>
        <div class="footer">
            <ul>
                <li>
                    <div class="box">
                        <span class="icon home"></span>
                        <span class="text">首页</span>
                    </div>
                </li>
                <li>
                  <div class="box">
                    <span class="icon wx"></span>
                    <span class="text">论坛</span>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <span class="icon me"></span>
                    <span class="text">我的</span>
                  </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Index from './index'
export default Index
</script>
<style lang="less">
@import "index";
</style>
