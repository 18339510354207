
const Index = {
    name:'Index',
    computed: {
    },
    data() {
        return {
            number:1
        }
    },
    created() {
    },
    methods: {
        onChange(n){
            this.number = n
        },
        onLink(){
            this.$router.push('/content')
        },
    },
    destroyed () {
    }
}
export default Index
